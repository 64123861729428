<template>
	<div class="login-register-from-space"  @click="riqifanshi=false">
		<div class="X5eogfqvbxD_ckZVQSll">{{ this.suportType }}<!--$t("Login._SuporteContaRegistro")--></div>
		<div v-if="$store.state.config.register_type == 'all'|| $store.state.config.register_type == 'name'">
			<div class="" style="margin-bottom: .2rem;">
				<antInput
					left-icon="a" :modelValue="formConter.user_name"
						@focus="onFocus"
						@blur="onBlur"
						@onInput="onInput"
						form-key='user_name'
						maxlength="16"
						:placeholder="$store.state.config.register_type == 'name'?'Digite o Conta':'Por favor, insira Conta'" :class="{
						'input-active':isFocus
					}"
				>
					<div slot="left" style="display: flex;align-items: center;">
						<svg-icon className="imageWrap"
							style="width: .26rem;height: .26rem;color: var(--theme-text-color-lighten);"
							icon-class="input_icon_zh--svgSprite:all" />
						<span class="ZEcfoKF3Q_zq5EqaOdUy">
							<i v-if="!formConter.user_name">*</i>
						</span>
					</div>
				</antInput>
			</div>
			<div class="ant-form-explain" v-if="fonData.user_name.value">{{fonData.user_name.value}}</div>
		</div>
		<div v-if="$store.state.config.register_type == 'phone'">
			<div class="" style="margin-bottom: .2rem;" >
				<antInput
					left-icon="a" :modelValue="formConter.phone"
						@focus="onFocus"
						@blur="onBlur"
						@onInput="onInput"
						form-key='phone'
						type="Number"
						:placeholder="$t('Login._DigiteoNúmerodoCelular')" :class="{
						'input-active':isFocus
					}"
				>
					<div slot="left" @click.stop="riqifanshi=!riqifanshi" class="icontest" style="display: flex;align-items: center;color: var(--theme-text-color-lighten);">
						<img style="width: 0.36rem; height: 0.27rem;" src="./../../assets/icons/images/Brazil.png" >
							<span style="font-size: .2rem;padding-left: .1rem;padding-right: .2rem;">+55</span>
							<span unselectable="on"  class="ant-select-arrow" style="padding-right: .3rem;user-select: none;"
							><i  :style="{
								transform:riqifanshi?'rotate(-180deg)':'',
								transition:'.3s',
								display:'block'
							}" class="anticon anticon-down ant-select-arrow-icon"><svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 35.969 21.998" data-icon="down" fill="currentColor" aria-hidden="true" focusable="false" class=""><path id="comm_icon_fh" d="M2209.28,137.564l-17.743-15.773a2,2,0,0,1-.271-3.058,2.037,2.037,0,0,1,.274-.23l17.74-15.77a1.992,1.992,0,0,1,2.817,2.816l-16.422,14.6,16.422,14.6a1.992,1.992,0,1,1-2.817,2.817Z" transform="translate(-102.181 2212.68) rotate(-90)"></path></svg></i></span>
							<span class="ZEcfoKF3Q_zq5EqaOdUy">
								<i v-if="!formConter.phone">*</i>
							</span>
					</div>
				</antInput>
				<div class="ant-select-dropdow"   @click.stop="riqifanshi=false" v-if="riqifanshi" style="width: auto;" >
					<div class="Conter_arrConte" style="width: auto;" >
						<div class="icontest" style="display: flex;align-items: center;color: var(--theme-text-color-lighten);width: auto;">
							<img style="width: 0.36rem; height: 0.27rem;" src="./../../assets/icons/images/Brazil.png" >
							<span style="padding-left: .1rem;padding-right: .4rem;">Brasil（+55）</span>
						</div>
					</div>
				</div>
			</div>
			<div class="ant-form-explain" v-if="fonData.phone.value">{{fonData.phone.value}}</div>
		</div>
		<div class="" style="margin-bottom: .2rem;">
			<antInput
				left-icon="a" :modelValue="formConter.password"
					@focus="onFocus"
					@blur="onBlur"
					@onInput="onInput"
					maxlength="16"
					form-key='password'
					:type="fonData.password.type"
					:placeholder="$store.state.config.register_type == 'name'?'Inserir Senha':$t('Login._Senha')" :class="{
				 	'input-active':isFocus
				 }"
			>
				<div slot="left" style="display: flex;align-items: center;">
					<svg-icon className="imageWrap"
						style="width: .26rem;height: .26rem;color: var(--theme-text-color-lighten);"
						icon-class="input_icon_mm--svgSprite:all" />
					<span class="ZEcfoKF3Q_zq5EqaOdUy">
						<i v-if="!formConter.password">*</i>
					</span>
				</div>
				<!-- fonData.password.type -->
				<div slot="right-icon" @click="showHide('password')">
					<svg-icon className="right-icon"
						style="width: .26rem;height: .26rem;color: var(--theme-text-color-lighten);"
						:icon-class="fonData.password.type=='text'?'comm_icon_show--svgSprite:all':'comm_icon_hide--svgSprite:all'" />
				</div>
			</antInput>
		</div>
		<div class="ant-form-explain" v-if="fonData.password.value">{{fonData.password.value}}</div>
		
		<div class="ant-space-item" style="margin-bottom: 0px;">
			<div class="ptEr7ybENfgNXBPMnsRb">
				<div class="rBX8yUXrZ3DzhJ4ZvZUD">
					<span>Força</span>
					<span v-for="index in 4" :key="index" :style="{
						    backgroundColor:mimaQiangdu==4?'var(--theme-secondary-color-success) !important':''
					}" :class="{
						QZ0QekXF9duW7ClyMSdQ:index<=mimaQiangdu && formConter.password,
						
					}" class="VYe1C86O9HyF7KaCqIUg "></span>
				</div>
			</div>
		</div>
		<!-- 1v-if="fonData.password_confirm." -->
		<div class="" style="margin-bottom: .2rem;" v-if="fonData.password.type!='text'" >
			<antInput
				left-icon="a" :modelValue="formConter.password_confirm"
					@focus="onFocus"
					@blur="onBlur"
					@onInput="onInput"
					maxlength="16"
					form-key='password_confirm'
					:type="fonData.password_confirm.type"
					:placeholder="$store.state.config.register_type == 'name'?'Por favor, confirme sua senha novamente':$t('Login._Confirme')" :class="{
				 	'input-active':isFocus
				 }"
			>
				<div slot="left" style="display: flex;align-items: center;">
					<svg-icon className="imageWrap"
						style="width: .26rem;height: .26rem;color: var(--theme-text-color-lighten);"
						icon-class="input_icon_mm--svgSprite:all" />
					<span class="ZEcfoKF3Q_zq5EqaOdUy">
						<i v-if="!formConter.password_confirm">*</i>
					</span>
				</div>
				<div slot="right-icon" @click="showHide('password_confirm')">
					<svg-icon className="right-icon"
						style="width: .26rem;height: .26rem;color: var(--theme-text-color-lighten);"
						:icon-class="fonData.password_confirm.type=='text'?'comm_icon_show--svgSprite:all':'comm_icon_hide--svgSprite:all'" />
				</div>
			</antInput>
		</div>
		<div class="ant-form-explain" v-if="fonData.password_confirm.value && fonData.password.type!='text'">{{fonData.password_confirm.value}}</div>
		<div v-if="$store.state.config.register_type == 'all'">
			<div class="" style="margin-bottom: .2rem;" >
				<antInput
					left-icon="a" :modelValue="formConter.phone"
						@focus="onFocus"
						@blur="onBlur"
						@onInput="onInput"
						form-key='phone'
						type="Number"
						:placeholder="$t('Login._DigiteoNúmerodoCelular')" :class="{
						'input-active':isFocus
					}"
				>
					<div slot="left" @click.stop="riqifanshi=!riqifanshi" class="icontest" style="display: flex;align-items: center;color: var(--theme-text-color-lighten);">
						<img style="width: 0.36rem; height: 0.27rem;" src="./../../assets/icons/images/Brazil.png" >
							<span style="font-size: .2rem;padding-left: .1rem;padding-right: .2rem;">+55</span>
							<span unselectable="on"  class="ant-select-arrow" style="padding-right: .3rem;user-select: none;"
							><i  :style="{
								transform:riqifanshi?'rotate(-180deg)':'',
								transition:'.3s',
								display:'block'
							}" class="anticon anticon-down ant-select-arrow-icon"><svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 35.969 21.998" data-icon="down" fill="currentColor" aria-hidden="true" focusable="false" class=""><path id="comm_icon_fh" d="M2209.28,137.564l-17.743-15.773a2,2,0,0,1-.271-3.058,2.037,2.037,0,0,1,.274-.23l17.74-15.77a1.992,1.992,0,0,1,2.817,2.816l-16.422,14.6,16.422,14.6a1.992,1.992,0,1,1-2.817,2.817Z" transform="translate(-102.181 2212.68) rotate(-90)"></path></svg></i></span>
							<span class="ZEcfoKF3Q_zq5EqaOdUy">
								<i v-if="!formConter.phone">*</i>
							</span>
					</div>
				</antInput>
				<div class="ant-select-dropdow"   @click.stop="riqifanshi=false" v-if="riqifanshi" style="width: auto;" >
					<div class="Conter_arrConte" style="width: auto;" >
						<div class="icontest" style="display: flex;align-items: center;color: var(--theme-text-color-lighten);width: auto;">
							<img style="width: 0.36rem; height: 0.27rem;" src="./../../assets/icons/images/Brazil.png" >
							<span style="padding-left: .1rem;padding-right: .4rem;">Brasil（+55）</span>
						</div>
					</div>
				</div>
			</div>
			<div class="ant-form-explain" v-if="fonData.phone.value">{{fonData.phone.value}}</div>
		</div>
		<ul class="HwvTcjBqOBJV3S7RoZ7g" v-if="false">
			<li class="xuanzeactive">
				<svg width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false"
					viewBox="0 0 43.998 44.002">
					<path id="联合_669" data-name="联合 669"
						d="M22141.994,23716v-1H22143v1Zm-42.992,0v-1h.994v1Zm42.992-43v-1H22143v1Zm-42.992,0v-1h.994v1Z"
						transform="translate(-22099.002 -23671.998)" opacity="0.04"></path>
					<path id="密码"
						d="M3381.12,1299.991v-1.8a7.158,7.158,0,0,0-6.7-7.2,7.039,7.039,0,0,0-7.411,6.992v2h-.283a1.726,1.726,0,0,0-1.732,1.719v11.562a1.726,1.726,0,0,0,1.732,1.719H3381.4a1.725,1.725,0,0,0,1.732-1.719V1301.71a1.725,1.725,0,0,0-1.732-1.719Zm-11.758-1.833a4.7,4.7,0,0,1,9.406,0v1.833h-9.406Zm6.212,12.278a.493.493,0,0,1-.126.388.508.508,0,0,1-.376.167h-2.015a.507.507,0,0,1-.376-.167.493.493,0,0,1-.125-.388l.318-2.837a1.971,1.971,0,0,1-.826-1.608,2.016,2.016,0,0,1,4.031,0,1.97,1.97,0,0,1-.824,1.608Z"
						transform="translate(-3351.994 -1280.989)"></path>
				</svg>
				<span>{{ $t("Login._Cadastrodesenha") }} </span>
			</li>
			<li>
				<svg width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false"
					viewBox="0 0 44 44">
					<g id="矩形_3452" data-name="矩形 3452" stroke-width="1" opacity="0">
						<rect width="44" height="44"></rect>
						<rect x="0.5" y="0.5" width="43" height="43"></rect>
					</g>
					<path id="路径_15116" data-name="路径 15116"
						d="M5.38.46V-12.2L2.16-9.4v-3.08l3.22-2.8H8.24V.46Zm7.98,0V-2.12l5.88-6.84a3.59,3.59,0,0,0,.62-.92,2.472,2.472,0,0,0,.18-.98,2.058,2.058,0,0,0-.48-1.42,1.775,1.775,0,0,0-1.4-.54,2.468,2.468,0,0,0-.7.1,1.743,1.743,0,0,0-.62.33,1.624,1.624,0,0,0-.45.62,2.373,2.373,0,0,0-.17.95H13.36a4.791,4.791,0,0,1,.37-1.93,4.222,4.222,0,0,1,1.01-1.44,4.34,4.34,0,0,1,1.52-.9,5.679,5.679,0,0,1,1.9-.31,5.579,5.579,0,0,1,1.92.32,4.439,4.439,0,0,1,1.51.9,4.071,4.071,0,0,1,.99,1.43,4.853,4.853,0,0,1,.36,1.91,4.128,4.128,0,0,1-.12,1.04,4.261,4.261,0,0,1-.33.87,5.166,5.166,0,0,1-.53.82q-.32.41-.7.87l-4.4,5.12h6.08V.46ZM34.72-4.1a4.938,4.938,0,0,1-.4,2.06A4.235,4.235,0,0,1,33.24-.57,4.343,4.343,0,0,1,31.65.3a6.527,6.527,0,0,1-1.93.28A6.635,6.635,0,0,1,27.85.32,4.568,4.568,0,0,1,26.26-.5a4.036,4.036,0,0,1-1.12-1.44,5.032,5.032,0,0,1-.44-2.1h2.88a2.422,2.422,0,0,0,.19.9,1.875,1.875,0,0,0,.46.64,1.91,1.91,0,0,0,.68.39,2.545,2.545,0,0,0,.81.13,2.11,2.11,0,0,0,1.53-.57,2.162,2.162,0,0,0,.59-1.63,2.192,2.192,0,0,0-.57-1.59,2.162,2.162,0,0,0-1.63-.59h-.42V-8.84h.42a1.843,1.843,0,0,0,1.52-.57,2.173,2.173,0,0,0,.46-1.41,1.878,1.878,0,0,0-.58-1.49,1.969,1.969,0,0,0-1.34-.51,1.947,1.947,0,0,0-1.36.49,1.927,1.927,0,0,0-.58,1.41H24.88a4.6,4.6,0,0,1,.41-1.89,4.293,4.293,0,0,1,1.03-1.4,4.527,4.527,0,0,1,1.51-.88,5.531,5.531,0,0,1,1.87-.31,5.426,5.426,0,0,1,1.92.33,4.594,4.594,0,0,1,1.52.92,4.154,4.154,0,0,1,1,1.42,4.551,4.551,0,0,1,.36,1.83,3.557,3.557,0,0,1-.52,2.04,3.667,3.667,0,0,1-1.3,1.18,4.038,4.038,0,0,1,1.44,1.3A3.911,3.911,0,0,1,34.72-4.1Z"
						transform="translate(3.836 29.4)"></path>

				</svg>
				<span>
					{{ $t("Login._Registrodocódigodeverificação") }}
					
				</span>
			</li>
		</ul>
		<!-- HwvTcjBqOBJV3S7RoZ7g -->
		
		<div v-if="$store.state.config.show_country" style="margin-bottom: .2rem;">
			<ant-select :value="value1" className="_max-width" :options="option1" @change="onChange">
			</ant-select>
		</div>
		<div class="inner-textbox">
			<span class="ke1kCzBARxq5HSxbDQ6h"><span class="TMZWYZcIv5YQXVdFMVrw inner-text"
					style="-webkit-line-clamp: 2;">{{ $t("Login._accAcrt") }}</span></span>
		</div>
		<div>
			<div class="van-checkbox__icon_" style="margin-bottom: .2rem;padding-right: .5rem;">
				<van-checkbox v-model="checked" shape="square" checked-color="var(--theme-secondary-color-success)"
					icon-size=".32rem">
					<span class="chen_texbt">{{ $t("Login._Tenhomaisde") }}<span class="data-user-agreement"
							data-user-agreement="true"> 《	{{ $t("Login._AcordodeUsuário") }}》<span></span></span></span>
				</van-checkbox>
			</div>
			<div class="" style="display: flex;align-items: center;padding-bottom: .2rem;">
				<button type="button"
					style="border-radius: .14rem;"
					@click="registerUrl"
					class="GaL3XJonIwzK4ZeJyCyq">
					{{ $t("Login._Registro") }}
				</button>
			</div>
		</div>
		<ul style="display: flex;align-items: center;color:var(--theme-primary-color);font-size: .22rem;line-height: .26rem;text-align: center;">
			<li style="width: 50%;" @click="openUrlLofin('notice')">	{{ $t("Login._Suporteaocliente") }}</li>
			<!-- notice -->
			<li style="width: 50%;" @click="openUrlLofin('subgame',{
				id:'',
				category:'demo'
			})">{{ $t("Login._JogarGrátis") }}</li>
		</ul>
	</div>

</template>

<script>
	function checkPasswordStrength(password) {
	  let score = 0;
	  if (password.length > 6) score++;
	  if (password.length > 0) score++;
	  if(password.length >= 6){
		  if (password.match(/[A-Z]/)) score++; // 大写字母
		  if (password.match(/[a-z]/)) score++; // 小写字母
		  if (password.match(/[0-9]/)) score++; // 数字
		  if (password.match(/[\W_]/)) score++; // 特殊字符
	  }
	  if (password.length > 10) score++;
	  switch (score) {
	    case 0:
	    case 1:
	    case 2:
	    case 3:
	    case 4:
	      return 1; // 弱
	    case 5:
	      return 2; // 中等
		case 6:
			return 3; // 强
	    case 7:
		default:
		  return 4; // 非常强
	  }
	}
	function validatePassword(password) {
	    if (password.length < 6) {
	            return false; // 密码长度必须为6位
	        }
	        // 定义正则表达式来检查不同的字符类型
	        const hasDigit = /\d/.test(password);
	        const hasLower = /[a-z]/.test(password);
	        const hasUpper = /[A-Z]/.test(password);
	        const hasSpecial = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(password); // 定义一些常见的特殊字符
	        // 计算不同类型的字符数（这里实际上只关心是否存在，所以是布尔值）
	        let typeCount = 0;
	        if (hasDigit) typeCount++;
	        if (hasLower) typeCount++;
	        if (hasUpper) typeCount++;
	        if (hasSpecial) typeCount++;
	        // 检查是否至少有两种字符类型
	        return typeCount >= 2;
	}
	import register from "./register.vue"
	import antInput from "./input.vue"
	import {pustRegister} from "@/apis/modules/login.js";
	import { Toast } from 'vant';
	export default {
		components:{
			antInput
		},
		props: {
			isRecord: {
				type: Boolean,
				default: true,
			}
		},
		data() {
			return {
				checked: true,
				isFocus: false,
				formConter:{
						user_name:'',
						phone:'',
						password:'',
						password_confirm:'',
						invite_id:0,
				},
				value: '',
				suportType: (this.$store.state.config.register_type == 'all' || this.$store.state.config.register_type == 'name') ? 'Suporta apenas Conta Registro' : 'Suporta apenas Número do Celular Registro',
				fonData: {
					user_name:{
						placeholder:'Por favor, insira Conta',
						tis:'Erro no formato da conta,4-16 caracteres, suporta inglês/números',
						value:''
					},
					password:{
						placeholder:'6-16 caracteres, incluindo pelo menos duas /n letras/números/símbolos',
						tis:'6-16 caracteres, incluindo pelo menos duas /n letras/números/símbolos',
						value:'',
						type:'password'
					},
					password_confirm:{
						placeholder:'6-16 caracteres, incluindo pelo menos duas letras/números/símbolos',
						tis:'Senha incorreta, digite novamente!',
						value:'',
						type:'password'
					},
					phone:{
						placeholder:'Número do CelularCampo não pode estar vazio',
						tis:'Número do CelularCampo não pode estar vazio',
						value:'',
						type:'password'
					}
					// 
				},
				mimaQiangdu:-1,
				value1: 0,
				option1: [{
					text: 'BRL（BRL）',
					value: 0
				}],
				text: '',
				captcha:()=>{},
				riqifanshi:false,
				iscaptcha_configId:false,
				rememberPasswordList: [],
				rememberPasswordC: [],
			}
		},
		created() {
			let _this=this;
			if(this.$store.state.config.captcha_config.id){
				this.$initGeetest4({
					captchaId:this.$store.state.config.captcha_config.id,
					product: 'bind',
					language:_this.$store.state.config.captcha_config.language
				}, function (captcha) {
					_this.captcha=captcha;
					captcha.onReady(function () {}).onSuccess(function () {
						var result = captcha.getValidate();
						result.captcha_id =_this.$store.state.config.captcha_config.id;
						_this.pustRegister()
					}).onError(function () {
				
					})
				});	
			}else{
				this.iscaptcha_configId=true;
			}
			
		},
		mounted() {
			// this.$refs.searchVal.toggle()
			let rememberPassword = window.localStorage.getItem('rememberPassword');
			if (rememberPassword) {
				let aem = JSON.parse(rememberPassword);
				const uniqueArray = Array.from(aem.reduce((map, item) => {
					map.set(item.account, item);
					return map;
				}, new Map()).values());
				//this.formConter={...aem[0]};
				this.rememberPasswordList = uniqueArray;
				this.rememberPasswordC = uniqueArray;
				window.localStorage.setItem('rememberPassword', JSON.stringify(uniqueArray))
			}
		},
		methods: {
			
			openUrlLofin(name,quser){
				this.openUrl(name,quser);
				this.$store.commit('isRecord')
			},
			pustRegister(){
				pustRegister({
					...this.formConter,
					invite_id:window.localStorage.getItem('inviteID')||0
				}).then(({data,msg,code})=>{
					if(code==0){
						// this.isLOding
						
						this.$emit('onLogin',{
							password:this.formConter.password,
							account:this.formConter.user_name || this.formConter.phone,
							isZhuzhe:true
						},this.rememberPasswordC)
						window.localStorage.setItem('CancelDisplay',this.$store.state.config.register_pop)
					}else{
						// Toast(msg);
						Toast.fail({
							message:this.myLineBreaks(msg),
							duration:1000
						});
					}
					
				})
			},
			registerUrl(form){
				let {user_name,password,phone}=this.formConter;
				let _istrue=false;
				if(this.$store.state.config.register_type ==='all'){
					if(!user_name || user_name.length<4){
					_istrue=true;
				}
				}
				if(this.$store.state.config.register_type !=='name'){
					if(!phone || phone.length<=9){
						_istrue=true;
					}
				}
				if(!password || password.length<6){
					_istrue=true;
				}
				if(_istrue){
					for(let i in this.formConter){
						this.onInput(this.formConter[i],i)
					}
					return false;
				}
				if(this.fonData.password.type=='text'){
					this.formConter.password_confirm=this.formConter.password
				}
				if(!this.iscaptcha_configId){
					this.captcha.showCaptcha();
				}else{
					this.pustRegister();
				}
			},
			showHide(e){
				this.fonData[e].type=this.fonData[e].type=='password'?'text':'password'
			},
			onFocus(e,formKey){
			  // if(!formKey){return false}
			  // let  formSo= this.formConter[formKey];
			  // let formValue= this.fonData[formKey];
			  // console.log(formValue,formKey)
			  // if(!formSo){
				 // formValue.value=formValue.placeholder 
			  // }
			},
			// @blur="onBlur"
			onBlur(e,formKey){
				if(!formKey){return false}
				let  formSo= this.formConter[formKey];
				let formValue= this.fonData[formKey];
				if(!formSo){
					formValue.value=formValue.placeholder 
				}
			},
			onInput(e,formKey){
				if(!formKey){return false}
				this.formConter[formKey]=e;
				let  formSo= this.formConter[formKey];
				let formValue= this.fonData[formKey];
				if(formSo){
					let regex = /^[a-zA-Z0-9_]{4,16}$/;
					if(formKey=='user_name' && !regex.test(e)){
						formValue.value=formValue.tis;
					}else if(formKey=='user_name' && regex.test(e)){
						if(/^\d+$/.test(e) && e.length>=6  && e.length<=12){
							formValue.value='A conta não suporta 6-12 dígitos puros, digite novamente'
							return false;
						}
						formValue.value='';
					}
					if(formKey=='password'){
						this.mimaQiangdu=formSo.length?checkPasswordStrength(e):-1;
						console.log(validatePassword(e))
						if(!validatePassword(e)){
							formValue.value=formValue.tis;
						}else{
							formValue.value='';
						}
					}
					if(formKey=='password_confirm' && formSo){
						if(formSo!=this.formConter.password){
							formValue.value=formValue.tis;
						}else if(formSo==this.formConter.password){
							formValue.value='';
						}
					}
					if(formKey=='phone'&& formSo){
						if( e.length<=9){
							this.guoqu=true;
							formValue.value ='O código de área +55 suporta 10-11 números de celular, digite novamente';
						}else if( e.length>11){
							this.guoqu=true;
							formValue.value = 'O código de área +55 suporta 10-11 números de celular, digite novamente';
						}else{
							formValue.value = '';
						}
					}
					
					
				}else{
					formValue.value=formValue.placeholder 
				}
				this.formConter={
					...this.formConter
				}
				
			},
			onChange() {
			
			},
			isFun(e) {
				this.isFocus = e;
			},
		}
	}
</script>
<style>
		
	.login-register-from-space .van-checkbox__icon_{
		position: relative;
		padding-left: .24rem;
	}
	.login-register-from-space .van-checkbox__icon_ .van-checkbox__icon{
		position: absolute;
		left: 0;
		top: .05rem;
	}
	.login-register-from-space .van-search {
		display: flex;
		align-items: center;
		border-radius: .14rem;
		font-size: .22rem;
		height: .7rem;
		background-color: var(--theme-main-bg-color);
		border: 1px solid #d9d9d9;
		padding-right: .1rem;
		border-color: var(--theme-color-line);
	}

	.login-register-from-space .van-checkbox__icon .van-icon {
		border-radius: .06rem;
		border-color: var(--theme-color-line);
	}
	.login-register-from-space .van-ellipsis{
		position: relative;
		padding-left: .5rem;
		display: flex;
		align-items: center;
		height: .5rem;
	}
	.login-register-from-space .van-cell__title{
		position: relative;
		padding-left: 0.42rem;
	}
	.login-register-from-space .van-dropdown-menu__title{
		padding: 0 !important;
	}
	.login-register-from-space .van-cell__title:before,.login-register-from-space .van-ellipsis:before{
			content: '';
			display: inline-block;
		    position: relative;
		    width: 0.36rem;
		    height: 0.36rem;
			position: absolute;
			left:0;
			overflow: hidden;
			background-image: url('./../../assets/5e8fdeaf-a95f-4566-adac-d84c9605a176.png');
			background-position: -8.58857rem -3.35571rem;
			background-size: 9.33429rem 9.10929rem;
	}
	.login-register-from-space .van-cell__title:before{
		left: 0;
		top: -.05rem !important;
	}
	
	.login-register-from-space .van-search__content {
		background-color: var(--theme-main-bg-color);
	}

	.login-register-from-space .van-checkbox__label {
		display: flex;
		align-items: center;
	}

	.login-register-from-space .data-user-agreement {
		color: var(--theme-primary-color)
	}
	.login-register-from-space input {
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	}

	.login-register-from-space input::-webkit-input-placeholder {
		/* 使用webkit内核的浏览器 */
		color: var(--theme-text-color-placeholder) !important;
		line-height: .3rem !important;
		font-size: .22rem !important;
		font-weight: 400 !important;
	}

	.login-register-from-space input:-moz-placeholder {
		/* Firefox版本4-18 */
		color: var(--theme-text-color-placeholder) !important;
		line-height: .3rem !important;
		font-size: .22rem !important;
		font-weight: 400 !important;
	}

	.login-register-from-space input::-moz-placeholder {
		/* Firefox版本19+ */
		color: var(--theme-text-color-placeholder) !important;
		line-height: .3rem !important;
		font-size: .22rem !important;
		font-weight: 400 !important;
	}

	.login-register-from-space input:-ms-input-placeholder {
		/* IE浏览器 */
		color: var(--theme-text-color-placeholder) !important;
		line-height: .3rem !important;
		font-size: .22rem !important;
		font-weight: 400 !important;
	}
	
	.van-field__body input {
		color: var(--theme-text-color-darken);
	}

	.input-active {
		/* var(--theme-primary-color); */
		box-shadow: 0 2px 0 rgba(0, 0, 0, .045);
		border: .02rem solid var(--theme-filter-active-color) !important;
	}

	.v0FZl0YCkb_Z5KOXk68M4._max-width {
		width: auto !important;
		max-width: initial !important;
	}

	.v0FZl0YCkb_Z5KOXk68M4._max-width .van-dropdown-menu__bar {
		height: .7rem;
	}

	.v0FZl0YCkb_Z5KOXk68M4._max-width .van-dropdown-menu__bar .van-dropdown-menu__item {
		border: .01rem solid var(--theme-color-line);
		background-color: var(--theme-main-bg-color);
		font-size: .2rem;
		height: .7rem !important;
		transition: all .3s cubic-bezier(.645, .045, .355, 1);
		border-radius: .1rem;
		line-height: .5rem;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 0 11px;
		padding-right: .4rem !important;
		color: var(--theme-text-color-lighten) !important;
	}

	.v0FZl0YCkb_Z5KOXk68M4._max-width .addcinte {
		height: .7rem;
	}

	.v0FZl0YCkb_Z5KOXk68M4._max-width .van-dropdown-menu__bar .van-ellipsis {
		color: var(--theme-text-color-darken);
	}

	.v0FZl0YCkb_Z5KOXk68M4._max-width .van-popup,
	.v0FZl0YCkb_Z5KOXk68M4._max-width .van-dropdown-item {
		width: 100%;
	}

	.v0FZl0YCkb_Z5KOXk68M4._max-width .van-dropdown-item {
		top: .8rem !important;
	}
	.login-register-body .van-search__content{
		padding-left: 0px !important;
		margin-left: -.05rem;
	}
	/* border: .01rem solid var(--theme-primary-color) !important; */
</style>
<style scoped>
	.icontest{
		height: .7rem;
		/* width: 1.2rem; */
		/* padding-right: .15rem; */
		position: relative;
	}
	.icontest:before {
	    background-color: var(--theme-color-line);
	    bottom: .1rem;
	    content: "";
	    height: auto;
	    position: absolute;
	    right: 0.15rem;
	    top: .1rem;
	    width: .01rem;
	}
	.Conter_arrConte .icontest:before{
		display: none; 
	}
	.X5eogfqvbxD_ckZVQSll {
		color: var(--theme-text-color-darken);
		font-size: .22rem;
		line-height: 1.28;
		padding: 0 .3rem .07rem 0;
		text-align: left
	}

	.login-register-from-space {
		padding-left: .3rem;
		padding-right: .3rem;
		box-sizing: border-box;
		padding-top: .2rem;
		width: 100%
	}

	.ZEcfoKF3Q_zq5EqaOdUy {
		color: var(--theme-secondary-color-error);
		display: flex;
		align-items: center;
		margin-left: .02rem;
		position: relative;
		padding-right: .09rem;
	}
	.ZEcfoKF3Q_zq5EqaOdUy i{
		position: absolute;
		/* top: -.01rem; */
	}
	
	.GaL3XJonIwzK4ZeJyCyq {
	    background-color: #fff;
	    background-image: none;
	    border: 1px solid #d9d9d9;
	    border-radius: 4px;
	    -webkit-box-shadow: 0 2px 0 rgba(0,0,0,.015);
	    box-shadow: 0 2px 0 rgba(0,0,0,.015);
	    color: rgba(0,0,0,.65);
	    cursor: pointer;
	    display: inline-block;
	    font-size: 14px;
	    font-weight: 400;
	    height: 32px;
	    line-height: 1.499;
	    padding: 0 15px;
	    position: relative;
	    text-align: center;
	    -ms-touch-action: manipulation;
	    touch-action: manipulation;
	    -webkit-transition: all .3s cubic-bezier(.645,.045,.355,1);
	    transition: all .3s cubic-bezier(.645,.045,.355,1);
	    -webkit-user-select: none;
	    -moz-user-select: none;
	    -ms-user-select: none;
	    user-select: none;
	    white-space: nowrap
	}
	.GaL3XJonIwzK4ZeJyCyq{
		    background-color: var(--theme-primary-color);
		    border-color: var(--theme-primary-color);
			    font-size: .24rem;
			    height: .7rem;
				width: 100%;
				color: var(--theme-primary-font-color);
				    text-shadow: none;
					    box-shadow: 0 2px 0 rgba(0, 0, 0, .045);
	}
	
	.chen_texbt {
		color: var(--theme-text-color-darken);
		font-size: .22rem;
	}

	.ant-form-explain {
		color: var(--theme-secondary-color-error);
		font-size: .18rem;
		transition: color .3s cubic-bezier(.215, .61, .355, 1);
		line-height: 1.5 !important;
		position: relative;
		padding-left: .3rem;
		margin-bottom: .2rem;
	}

	.ant-form-explain::before {
		background-color: var(--theme-secondary-color-error);
		content: "";
		height: 0.18rem;
		margin-right: 0.1rem;
		visibility: visible;
		left: 0;
		top: 50%;
		margin-top: -0.09rem;
		width: 0.18rem;
		display: inline-block;
		position: absolute;
		background-image: url('./../../assets/5e8fdeaf-a95f-4566-adac-d84c9605a176.png');
		background-size: 13.068rem 12.753rem;
		border-radius: 50%;
		background-position: -12.888rem -3.825rem;
	}

	.HwvTcjBqOBJV3S7RoZ7g {
		display: flex;
		align-items: center;
		min-height: .26rem;
		font-size: .22rem;
		margin-bottom: .2rem;
	}

	.HwvTcjBqOBJV3S7RoZ7g li {
		display: flex;
		width: 50%;
		color: var(--theme-text-color-darken);
	}

	.HwvTcjBqOBJV3S7RoZ7g svg {
		-ms-flex-align: center;
		-ms-flex-pack: center;
		align-items: center;
		background-color: var(--theme-main-bg-color);
		border: .01rem solid var(--theme-color-line);
		border-radius: 9999.99rem;
		color: var(--theme-text-color-lighten);
		display: -ms-flexbox;
		display: flex;
		font-size: .3rem;
		min-width: .3rem;
		justify-content: center;
		line-height: 1;
		margin-right: .1rem;
	}

	.xuanzeactive {
		color: var(--theme-primary-color) !important;
	}

	.xuanzeactive svg {
		color: #000 !important;
		border: .01rem solid var(--theme-primary-color);
		background-color: var(--theme-primary-color);
	}

	.right-icon {
		height: .36rem !important;
		width: .36rem !important;
	}

	.rBX8yUXrZ3DzhJ4ZvZUD {
		display: flex;
		align-items: center;
		margin-bottom: .2rem;
	}

	.ptEr7ybENfgNXBPMnsRb .rBX8yUXrZ3DzhJ4ZvZUD span {
		color: var(--theme-text-color-darken);
		font-size: .22rem;
		margin-right: .1rem;
		-webkit-transition: all .3s;
		transition: all .3s;
	}

	.ptEr7ybENfgNXBPMnsRb .rBX8yUXrZ3DzhJ4ZvZUD .VYe1C86O9HyF7KaCqIUg {
		background-color: var(--theme-color-line);
		border-radius: .12rem;
		height: .12rem;
		margin-right: .1rem;
		transition: all .3s;
		width: .8rem;
	}

	.ptEr7ybENfgNXBPMnsRb .rBX8yUXrZ3DzhJ4ZvZUD .QZ0QekXF9duW7ClyMSdQ {
		background-color: var(--theme-secondary-color-error) !important;
	}

	.inner-textbox {
		text-align: left !important;
		line-height: 1.5;
		margin-bottom: .2rem;
		font-size: 9.36px;
		color: var(--theme-secondary-color-finance);
	}

	/* .ant-select-selection__placeholder {
	    height: .3rem;
	    line-height: .3rem;
		font-size: .22rem;
		    font-weight: 400;
	    margin-top: -.15rem;
		    color: var(--theme-text-color-placeholder);
	} */
</style>
